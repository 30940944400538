h1 {
    font-size: 22px !important;
}

#searchInput::placeholder {
    color: #bbb;
}

.result-item {
    padding: 8px 10px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
    min-height: 70px;
    border-radius: 5px;
    box-shadow: 0 0px 10px rgba(10,37,64,0.20);
}

.result-item .item-label, .result-item .item-brand {
    line-height: normal;
    font-size: 14px;
}

.result-item img {
    max-height: 100px;
}

.item-info .badge {
    margin-right: 3px;
    padding: 4px 7px;
}

.item-info .badge:not(:first-child) {
    background-color: rgba(153, 153, 153, 0.78) !important;
}

#barcodeIcon {
    position: absolute;
    right: 10px;
    top: 5px;
    color: #333;
    cursor: pointer;
}

#eanCodeScanner {
    position: absolute;
    right: 8px;
    top: 32px;
    color: #333;
    cursor: pointer;
}

video {
    background-color: #000;
}

.form-group {
    margin-top: 15px;
}

form label {
    font-weight: 600;
    margin-bottom: 0.1rem !important;
}

#eanCode .form-control.is-invalid, #eanCode .was-validated .form-control:invalid {
    background: none;
}

#submit-btn-block {
    margin-bottom: 100px;
}

.item-actions {
    position: absolute !important;
    right: 8px;
    top: 0;
}

.item-actions button {
    border-color: #ddd !important;
}

#productsByName {
    padding: 15px;
    border: 1px solid #cccccc;
}

.productByName {
    cursor: pointer;
    font-size: 13px;
    padding: 5px 8px;
    border-bottom: 1px solid #cccccc;
}

.productByName img {
    max-width: 50px;
}

#productsByNameClose {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

.loading-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #007bff; /* Set your desired color */
    z-index: 9999;
    overflow: hidden;
}

.loading-bar {
    height: 100%;
    width: 0;
    background-color: #007bff; /* Set your desired color */
    animation: progressAnimation 2s linear infinite;
}

@keyframes progressAnimation {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

#footer .btn-group .btn:first-child {
    border-radius: 10px 0 0 0 !important;
    border: 1px solid #cccccc !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
}

#footer .btn-group .btn:last-child {
    border-radius: 0 10px 0 0 !important;
    border: 1px solid #cccccc !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
}
